import React from 'react'
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Hex = ({ name, img, dir }) => {
    return (
        <OverlayTrigger
            placement={dir}
            overlay={<Tooltip>{name}</Tooltip>}
        >
            <div className="hex2">
                <img
                    alt=""
                    src={img}
                    style={{ height: "105%", width: "105%" }}
                />
            </div>
        </OverlayTrigger>
    )
}

export default Hex
