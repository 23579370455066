import React from 'react'
import Container from "react-bootstrap/Container";
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "./Card";
import pro1 from "../../../images/pro1.png";
import pro1m from "../../../images/pro1m.png";
import pro2 from "../../../images/pro2.png";
import pro2m from "../../../images/pro2m.png";
import pro3 from "../../../images/pro3.png";
import pro3m from "../../../images/pro3m.png";
import pro4m from "../../../images/pro4m.png";
import inv from "../../../images/inv.png";
import invm from "../../../images/invm.png";
import "swiper/swiper-bundle.css";
import "./Cards.css";

const MyWork = ({ mobile }) => {
    SwiperCore.use([Autoplay]);
    const [data, setData] = React.useState([
        {
            name: "Art Gallery",
            para: "Full-Stack Web Application",
            links: {
                github: "https://github.com/seerde/Project-2",
                url: "https://artgalleryproject.herokuapp.com/home",
                img: pro2,
                imgm: pro2m,
            },
        },
        {
            name: "Online Courses",
            para: "Full-Stack Web Application",
            links: {
                github: "https://github.com/seerde/Project-3",
                url: "https://online-courses-project.herokuapp.com/",
                img: pro3,
                imgm: pro3m,
            },
        },
        {
            name: "Frogger",
            para: "Javascript Arcade Game",
            links: {
                github: "https://github.com/seerde/Project-1",
                url:
                    "https://pages.git.generalassemb.ly/alyamaniedrees/Project_Prompt_1/",
                img: pro1,
                imgm: pro1m,
            },
        },
        {
            name: "Kharbsha",
            para: "Full-Stack Web Application Game",
            links: {
                github: "https://github.com/seerde/Project-4",
                url: "https://kharbsha.herokuapp.com/",
                img:
                    "https://raw.githubusercontent.com/seerde/Project-4/master/images/game2.PNG",
                imgm: pro4m,
            },
        },
        {
            name: "Invoicing",
            para: "C# Application",
            links: {
                github: "https://github.com/seerde/invoicing_project",
                url: "#",
                img: inv,
                imgm: invm,
            },
        },
    ]);
    return (
        <div className="container-c">
            {/* <div className="swiper-container"> */}
            <Swiper
                initialSlide={0}
                spaceBetween="1"
                speed={600}
                loop
                effect="coverflow"
                coverflowEffect={{
                    rotate: 0,
                    stretch: 500,
                    depth: 300,
                    modifier: 1,
                    slideShadows: !1,
                }}
                slidesPerView="auto"
                loopedSlides={1}
                slideToClickedSlide={!0}
                breakpoints={{ 320: { slidesPerView: 1, spaceBetween: 0 } }}
                autoplay={{
                    delay: "3000",
                    disableOnInteraction: "true",
                }}
            >
                {data.map((e, k) => {
                    return (
                        <SwiperSlide key={k}>
                            <Container>
                                <Card data={e} mobile={mobile} />
                            </Container>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    )
}

export default MyWork
