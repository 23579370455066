import React from 'react'
import { BsChevronCompactRight } from "react-icons/bs"
import { FaGithub } from "react-icons/fa"
import { GoBrowser } from "react-icons/go"

const Card = ({ data, mobile }) => {
    return (
        <div className="cardContainer">
            {/* <h4 className="text-center"><strong>STYLE 1</strong></h4> */}
            <hr />
            <div className="profile-card-2">
                <img src={mobile ? data.links.imgm : data.links.img} className="img img-responsive" width="100%" height="500" />
                <div className="profile-name">{data.name}</div>
                <div className="profile-username" >{data.para}</div>
                <div className="profile-icons"><a className="github" target="_blank" href={data.links.github}><FaGithub style={{ fontSize: "48px" }} /></a><a className="github" target="_blank" href={data.links.url}><GoBrowser style={{ paddingLeft: "5px", fontSize: "52px" }} /></a></div>
                <BsChevronCompactRight id="nextArrow" />
            </div>
        </div>
    )
}

export default Card
