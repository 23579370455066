import firebase from "firebase/app";

firebase.initializeApp({
    apiKey: "AIzaSyBYJRIW6q3TPh51luPDNJe8OduR9_mlstw",
    authDomain: "portfolio-1f4a1.firebaseapp.com",
    projectId: "portfolio-1f4a1",
    storageBucket: "portfolio-1f4a1.appspot.com",
    messagingSenderId: "895972663138",
    appId: "1:895972663138:web:c1b8935405346d7226d63b",
    measurementId: "G-K7W7N3KJTX"
});
export default firebase;