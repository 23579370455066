import React from 'react'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaLinkedin, FaGithubSquare } from "react-icons/fa";

import Hex from "./Hex.js";
import Square from "./Square.js";

import seerde from "../../../images/hex/seerde.jpg";
import js from "../../../images/hex/js.png";
import html from "../../../images/hex/html.png";
import css from "../../../images/hex/css.png";
import nodejs from "../../../images/hex/node.png";
import git from "../../../images/hex/git.png";
import python from "../../../images/hex/python.png";
import cpro from "../../../images/hex/cpro.png";
import linux from "../../../images/hex/linux.png";
import csh from "../../../images/hex/csh.png";
import mongodb from "../../../images/hex/mongodb.png";

import ar from "../../../images/hex/ar.png";
import en from "../../../images/hex/en.png";
import kr from "../../../images/hex/kr.png";

const AboutMe = () => {
    const [data, setData] = React.useState([
        {
            name: "Javascript programming",
            img: js,
            dir: "top"
        },
        {
            name: "HTML",
            img: html,
            dir: "top"
        },
        {
            name: "CSS",
            img: css,
            dir: "top"
        },
        {
            name: "Node.js",
            img: nodejs,
            dir: "top"
        },
        {
            name: "Git/Github (Source control)",
            img: git,
            dir: "top"
        },
        {
            name: "Python programming",
            img: python,
            dir: "top"
        },
        {
            name: "C programming",
            img: cpro,
            dir: "top"
        },
        {
            name: "Linux",
            img: linux,
            dir: "bottom"
        },
        {
            name: "MongoDB database (NoSQL)",
            img: mongodb,
            dir: "bottom"
        },
        {
            name: "C# programming",
            img: csh,
            dir: "bottom"
        },
    ]);
    const [lang, setLang] = React.useState([
        {
            name: "Arabic",
            img: ar
        },
        {
            name: "English",
            img: en
        },
        {
            name: "Korean",
            img: kr
        }
    ])
    return (
        <Container className="aboutmeContainer">
            <Container className="mb-5"></Container>
            <Container>
                <Row>
                    <Col>
                        <Row className="justify-content-center">
                            <Col sm="auto" style={{ textAlign: "center" }}>
                                <h1 style={{ fontWeight: "bold" }}>About Me</h1>
                                <hr className="hrLine" />
                            </Col>
                        </Row>
                        <Row className="justify-content-center mb-3 mt-3">
                            <div className="hex">
                                <img
                                    src={seerde}
                                    style={{ marginTop: "10%", marginLeft: "1%" }}
                                    width="300"
                                    height="300"
                                />
                            </div>
                        </Row>
                        <Row className="justify-content-center mb-3">
                            <a href="https://www.linkedin.com/in/edrees-alyamani" target="_blank" style={{ color: "white" }}><FaLinkedin className="pr-1" style={{ fontSize: "38px" }} /></a>
                            <a href="https://github.com/seerde" target="_blank" style={{ color: "white" }}><FaGithubSquare className="pl-1" style={{ fontSize: "38px" }} /></a>
                        </Row>
                        <Row className="justify-content-center">
                            <h3 style={{ fontWeight: "bold" }}>Who's Edrees?</h3>
                        </Row>
                        <Row className="justify-content-center">
                            <p
                                style={{
                                    textAlign: "center",
                                    fontSize: "18px",
                                    lineHeight: "16pt",
                                }}
                            >
                                I'm an enthusiastic Software engineer with background in
                                computer engineering working for RCMC, who is a lover of science
                                and knowledge. Offering high quality, program design and
                                effective performance. Motivated by creatively solving complex
                                problems and solution synthesis.
              </p>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="mt-5">
                            <Col style={{ textAlign: "center" }}>
                                <h2 style={{ fontWeight: "bold" }}>Skills</h2>
                                <hr className="hrLine" />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="hexContainer">
                                <section className="hexagon-gallery">
                                    {data.map(e => (<Hex name={e.name} img={e.img} dir={e.dir} />))}
                                </section>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col style={{ textAlign: "center" }}>
                                <h2 style={{ fontWeight: "bold" }}>Languages</h2>
                                <hr className="hrLine" />
                            </Col>
                        </Row>
                        <Row className="m-5 justify-content-center hexContainer">
                            {lang.map(e => (<Square name={e.name} img={e.img} />))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default AboutMe
