import React from 'react'
import Radium, { StyleRoot } from "radium";
import Draggable from "react-draggable";
import zoomInLeft from "react-animations/lib/zoom-in-left";

import AboutMe from "./AboutMe/AboutMe.js";
import MyWork from "./MyWork/MyWork.js"
import ContactMe from "./ContactMe/ContactMe.js"
import icon from "../../images/favicon.ico";
import plus from "../../images/plus.png";

const Browser = ({ mobile, closeHandler, zindex, up, window, title }) => {
    const styles = {
        zoomInLeft: {
            animation: "x 1s",
            animationName: Radium.keyframes(zoomInLeft, "zoomInLeft")
        }
    };
    const handleClose = (e) => {
        closeHandler(e);
    }
    return (
        <StyleRoot>
            <Draggable id="wAbsu" handle="strong" onMouseDown={() => { up(window) }}>
                <div
                    className="browserContainer"
                    id="wAbsu"
                    style={{ ...styles.zoomInLeft, zIndex: `${zindex}` }}
                >
                    <div className="browserTabButtons" style={{ position: "absolute" }}>
                        <div
                            className="browserTabButton"
                            id="b1"
                            onClick={() => {
                                handleClose(false);
                            }}
                        ></div>
                        <div
                            className="browserTabButton"
                            id="b2"
                            onClick={() => {
                                handleClose(false);
                            }}
                        ></div>
                        <div className="browserTabButton" id="b3"></div>
                    </div>
                    <strong>
                        <div className="browserTab">
                            <div className="browserTabButtons">
                                <div className="browserTabButton"></div>
                                <div className="browserTabButton"></div>
                                <div className="browserTabButton"></div>
                            </div>
                            <div className="browserTabBrackets">
                                <div className="browserTabBracket">{"<"}</div>
                                <div className="browserTabBracket">{">"}</div>
                            </div>
                            <div className="browserTabTitle">
                                <span>www.seerde.com/{window}.html{/* | About Me*/}</span>
                            </div>
                        </div>
                    </strong>
                    <div className="browserTabs">
                        <div className="browserTab2">
                            <img src={icon} width="15" height="15" />
                            <span>{title}</span>
                        </div>
                        <div className="browserNewTabBtn">
                            <img src={plus} width="25" height="25" />
                        </div>
                    </div>
                    <div className="browserBody scrollbar">
                        {window === "aboutMe" ? <AboutMe /> : null}
                        {window === "myWork" ? <MyWork mobile={mobile} /> : null}
                        {window === "contactMe" ? <ContactMe /> : null}
                    </div>
                </div>
            </Draggable>
        </StyleRoot>
    )
}

export default Browser
