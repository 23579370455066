import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./portfolio.css";

import Navigation from "./components/Navigation";
import Terminal from "./components/Terminal"
import Browser from "./components/Browser/Browser.js"

function App() {
  const [current, setCurrent] = React.useState("Terminal");
  const [aboutMe, setAboutMe] = React.useState(false);
  const [myWork, setMyWork] = React.useState(false);
  const [contactMe, setContactMe] = React.useState(false);
  const [zindex, setZindex] = React.useState({
    terminalZ: 0,
    aboutMeZ: 1,
    myWorkZ: 2,
    contactMeZ: 3
  })
  const [mobile, setMobile] = React.useState(false);

  const chosenWindowHandler = (window) => {
    if (window === "aboutMe")
      setAboutMe(true);
    if (window === "myWork")
      setMyWork(true);
    if (window === "contactMe")
      setContactMe(true);
  }

  const bringUp = (window) => {
    let tmpObj = { ...zindex };
    const max = Object.keys(tmpObj).length - 1;
    if (tmpObj[`${window}Z`] !== max) {
      for (const prop in tmpObj) {
        tmpObj[prop] = prop.includes(window) ? max : tmpObj[prop] - 1
        tmpObj[prop] = tmpObj[prop] < 0 ? 0 : tmpObj[prop]
      }
      setCurrent(window.includes("terminal") ? "Terminal" : "Browser");
      setZindex({ ...tmpObj });
    }
  }

  React.useEffect(() => {
    if (window.innerWidth <= 615 && !mobile) {
      setMobile(true);
    }
    else if (window.innerWidth > 615 && mobile) {
      setMobile(false);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 615 && !mobile) {
        setMobile(true);
      }
      else if (window.innerWidth > 615 && mobile) {
        setMobile(false);
      }
    });
  }, [mobile])

  return (
    <>
      <Navigation mobile={mobile} current={current} />
      <Container>
        <Row>
          <Col>
            <Terminal window={(e) => chosenWindowHandler(e)} zindex={zindex.terminalZ} up={(e) => bringUp(e)} />
            {aboutMe ? <Browser mobile={mobile} up={(e) => { bringUp(e) }} closeHandler={(e) => { setAboutMe(e) }} zindex={zindex.aboutMeZ} window="aboutMe" title="About Me" /> : null}
            {myWork ? <Browser mobile={mobile} up={(e) => { bringUp(e) }} closeHandler={(e) => { setMyWork(e) }} zindex={zindex.myWorkZ} window="myWork" title="My Work" /> : null}
            {contactMe ? <Browser mobile={mobile} up={(e) => { bringUp(e) }} closeHandler={(e) => { setContactMe(e) }} zindex={zindex.contactMeZ} window="contactMe" title="Contact Me" /> : null}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default App;
