import React from 'react'
import Tilty from "react-tilty";
import firebase from "../../../Firebase";
import mail from "../../../images/mail2.png"
import swal from "sweetalert";
import 'firebase/firestore';
import "./ContactMe.css";
import "../../../fonts/font-awesome-4.7.0/css/font-awesome.min.css"

const firestore = firebase.firestore();

const ContactMe = () => {
    const [validation, setValidation] = React.useState({
        name: true,
        email: true,
        subject: true,
        message: true
    })
    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [subject, setSubject] = React.useState("");
    const [message, setMessage] = React.useState("");

    const contactsRef = firestore.collection("contacts");
    const sendContact = async () => {
        await contactsRef.add({
            name: name,
            email: email,
            subject: subject,
            message: message,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        })
        swal("Submitted!", "Thank You for Contacting Me!", "success");
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(`${name}, ${subject}, ${message}, ${email}`)
        let tmpObj = { ...validation };
        tmpObj.name = name.trim() == '' ? false : true;
        tmpObj.subject = subject.trim() == '' ? false : true;
        tmpObj.message = message.trim() == '' ? false : true;
        tmpObj.email = email.trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null ? false : true;
        setValidation({ ...tmpObj })
        if (tmpObj.name && tmpObj.email && tmpObj.subject && tmpObj.message) {
            console.log("pass");
            sendContact();
        }
    }

    return (
        <div className="contact1">
            <div className="container-contact1">
                <Tilty perspective={400} >
                    <div className="contact1-pic js-tilt" data-tilt>
                        <img src={mail} alt="IMG" />
                    </div>
                </Tilty>

                <form className="contact1-form validate-form">
                    <span className="contact1-form-title">Contact Me</span>

                    <div className={`wrap-input1 validate-input ${validation.name ? "" : "alert-validate"}`} data-validate="Name is required">
                        <input className="input1" type="text" name="name" placeholder="Name" onChange={(e) => setName(e.target.value)} />
                        <span className="shadow-input1"></span>
                    </div>

                    <div className={`wrap-input1 validate-input ${validation.email ? "" : "alert-validate"}`} data-validate="Valid email is required: ex@abc.xyz">
                        <input className="input1" type="text" name="email" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                        <span className="shadow-input1"></span>
                    </div>

                    <div className={`wrap-input1 validate-input ${validation.subject ? "" : "alert-validate"}`} data-validate="Subject is required">
                        <input className="input1" type="text" name="subject" placeholder="Subject" onChange={(e) => setSubject(e.target.value)} />
                        <span className="shadow-input1"></span>
                    </div>

                    <div className={`wrap-input1 validate-input ${validation.message ? "" : "alert-validate"}`} data-validate="Message is required">
                        <textarea className="input1" name="message" placeholder="Message" onChange={(e) => setMessage(e.target.value)} ></textarea>
                        <span className="shadow-input1"></span>
                    </div>

                    <div className="container-contact1-form-btn">
                        <button className="contact1-form-btn" onClick={handleSubmit}>
                            <span>
                                Send Email<i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ContactMe
