import React from 'react'
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Square = ({ name, img }) => {
    return (
        <Col style={{ width: "23%" }}>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{name}</Tooltip>}
            >
                <img alt="" width="100" src={img} />
            </OverlayTrigger>
        </Col>
    )
}

export default Square
