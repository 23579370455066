import React from 'react'
import Radium, { StyleRoot } from "radium";
import Draggable from "react-draggable";
import zoomInLeft from "react-animations/lib/zoom-in-left";
import Typed from "react-typed";

const Terminal = ({ window, zindex, up }) => {
    const [currentTyped, setCurrentTyped] = React.useState(0);
    const styles = {
        zoomInLeft: {
            animation: "x 1s",
            animationName: Radium.keyframes(zoomInLeft, "zoomInLeft"),
        }
    };
    let string = "cd sei/projects/portfolio/^1000\n`<span class='color1'>Seerde</span>@<span class='color1'>Seerde</span><span class='color2'>:</span><span class='color4'>~sei/projects/portfolio/</span>$ `^1000ls^1000\n``";

    return (
        <StyleRoot>
            <Draggable id="tAbsu" handle="strong" onMouseDown={() => { up("terminal") }}>
                <div
                    className="terminalContainer"
                    id="tAbsu"
                    style={{ ...styles.zoomInLeft, zIndex: `${zindex}` }}
                >
                    <strong>
                        <div className="terminalTab">
                            <div className="terminalTabButtons">
                                <div className="terminalTabButton" id="b1"></div>
                                <div className="terminalTabButton" id="b2"></div>
                                <div className="terminalTabButton" id="b3"></div>
                            </div>
                            <span className="terminalTabTitle">
                                seerde.js — bash — 80x
                      </span>
                            <span className="terminalTabHeight">50</span>
                        </div>
                    </strong>
                    <div className="terminalBody scrollbar">
                        <span>
                            <span className="color1">Seerde</span>@
                      <span className="color1">Seerde</span>
                            <span className="color2">:</span>
                            <span className="color3">~</span>${" "}
                        </span>
                        <Typed
                            strings={[string]}
                            typeSpeed={50}
                            startDelay={2000}
                            style={{ whiteSpace: "pre" }}
                            onComplete={(self) => {
                                self.cursor.remove();
                                setCurrentTyped(currentTyped + 1);
                            }}
                        />
                        {currentTyped === 1 ? (
                            <div className="links">
                                <a
                                    href="#"
                                    onClick={() => {
                                        up("aboutMe");
                                        window("aboutMe");
                                    }}
                                >
                                    /AboutMe.html
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a
                                    href="#"
                                    onClick={() => {
                                        up("myWork");
                                        window("myWork");
                                    }}
                                >
                                    /MyWork.html
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <a
                                    href="#"
                                    onClick={() => {
                                        up("contactMe");
                                        window("contactMe");
                                    }}
                                >
                                    /ContactMe.html
                        </a>
                            </div>
                        ) : (
                                <></>
                            )}
                    </div>
                </div>
            </Draggable>
        </StyleRoot>
    )
}

export default Terminal
