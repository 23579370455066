import React from 'react';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Moment from "react-moment";
import { BsBatteryFull, BsWifi } from "react-icons/bs";
import icont from "../images/icon_t.png";

const Navigation = ({ mobile, current }) => {
    return (
        <Navbar fixed="top" className="navBar">
            <Navbar.Brand href="#"></Navbar.Brand>
            <Navbar.Collapse>
                <img
                    src={icont}
                    width="20"
                    height="20"
                    className="d-inline-block align-top"
                />
                <Nav className="mr-auto">
                    <Nav.Link disabled>
                        <strong>{current}</strong>
                    </Nav.Link>
                    {mobile ?
                        <></>
                        : <div style={{ display: "flex", width: "30px" }}>
                            <Nav.Link disabled>File</Nav.Link>
                            <Nav.Link disabled>Edit</Nav.Link>
                            <Nav.Link disabled>View</Nav.Link>
                            <Nav.Link disabled>Go</Nav.Link>
                            <Nav.Link disabled>Window</Nav.Link>
                            <Nav.Link disabled>Help</Nav.Link>
                        </div>}
                </Nav>
                <Nav>
                    <Nav.Link
                        style={{ fontSize: "23px", padding: "0", marginRight: "10px" }}
                        disabled
                    >
                        <BsWifi />
                    </Nav.Link>
                    <Nav.Link style={{ fontSize: "23px", padding: "0" }} disabled>
                        <BsBatteryFull />
                    </Nav.Link>
                    <Nav.Link disabled>
                        <Moment
                            data={new Date().getTime()}
                            interval={1000}
                            format="ddd h:mm A"
                        ></Moment>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Navigation
